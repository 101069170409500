import http from '@/services/http';

const usefulDocuments = {
  getUsefulDocuments(params) {
    return http.request({
      method: 'get',
      url: '/ra-useful-documents',
      params,
    });
  },
};

export default usefulDocuments;
