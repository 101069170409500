<template>
  <el-container>
    <el-header>
      <Header @toggle-menu="isCollapse = !isCollapse" />
    </el-header>
    <el-container class="main-container">
      <el-aside
        class="navbar"
        v-bind:class="{ 'navbar--closed': isCollapse }"
        v-bind:style='{ "width": (isCollapse? "64px" : "245px" ) }'>
        <NavBar />
      </el-aside>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { optionsApi } from '@/api/index';
import store from '@/store';
import { mapGetters, mapActions } from 'vuex';

import auth from '@/services/auth';
import NavBar from '@/components/NavBar.vue';
import Header from '@/components/Header.vue';

export default {
  name: 'PostLogin',
  data() {
    return {
      isCollapse: false,
    };
  },
  components: {
    NavBar,
    Header,
  },
  created() {
    this.getRemoteOptions();
  },
  computed: {
    ...mapGetters('users', ['currentUser']),
  },
  methods: {
    ...mapActions('options', ['setOptions']),
    async getRemoteOptions() {
      try {
        const response = await optionsApi.getOptions();
        store.dispatch('options/setOptions', response.data.acf);
      } catch (error) {
        if (error && error.response) {
          this.$notify({
            type: 'error',
            dangerouslyUseHTMLString: true,
            message: error.response.data.message,
          });
        }
        throw error;
      }
    },
  },
  watch: {
    currentUser(newVal) {
      if (newVal === null) {
        auth.logout();
        this.$router.push({ name: 'Login' });
      }
    },
  },
};
</script>
