import http from '@/services/http';

const communications = {
  getCommunications(params) {
    return http.request({
      method: 'get',
      url: '/communications',
      params,
    });
  },
  getCommunication(id) {
    return http.request({
      method: 'get',
      url: `/communications/${id}`,
    });
  },
};

export default communications;
