<template>
  <div class="user-groups">
    <img
      class="user-groups__image"
      :src="currentUser.user_group.image_url" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ExternalLinks',
  computed: {
    ...mapGetters('users', ['currentUser']),
  },
};
</script>
