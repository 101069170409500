export const namespaced = true;

export const mutations = {
  SET_CURRENT_USER(state, user) {
    state.currentUser = user;
  },
};

export const actions = {
  setCurrentUser({ commit }, userData) {
    commit('SET_CURRENT_USER', userData);
    return userData;
  },
};

export const getters = {
  currentUser: (state) => state.currentUser,
};

export const state = {
  currentUser: null,
};
