import storage from 'store';

export default {
  logout() {
    storage.remove('authToken');
  },
  authTokenExists() {
    return !!storage.get('authToken');
  },
};
