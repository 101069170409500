import http from '@/services/http';

const options = {
  getOptions() {
    return http.request({
      method: 'get',
      baseURL: process.env.VUE_APP_API_ACF_URL,
      url: '/options/reserved-area-options',
    });
  },
};

export default options;
