// Users routes
export default [{
  path: 'user/:username',
  name: 'User',
  meta: {
    title: 'user.profile',
  },
  component: () => import(/* webpackChunkName: "user" */ '../views/users/User.vue'),
  props: true,
}];
