const menuItems = [
  {
    name: 'Dashboard',
    label: 'dashboard',
    icon: 'dashboard',
  },
  {
    name: 'Statistics',
    label: 'statistics',
    icon: 'statistics',
  },
  {
    name: 'Products',
    label: 'products',
    icon: 'products',
  },
  {
    name: 'InvestmentAdvisory',
    label: 'investmentAdvisory',
    icon: 'investment',
  },
  {
    name: 'Communications',
    label: 'communications',
    icon: 'communications',
  },
  {
    name: 'UsefulDocuments',
    label: 'usefulDocuments',
    icon: 'useful-docs',
  },
  {
    name: 'Faqs',
    label: 'faq',
    icon: 'faq',
  },
];

export default menuItems;
