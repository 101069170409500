// FAQ routes
export default [{
  path: 'faq',
  name: 'Faqs',
  meta: {
    title: 'navbar.faq',
  },
  redirect: 'faq/products',
  component: () => import(/* webpackChunkName: "faqs" */ '../views/faq/Faqs.vue'),
  props: true,
  children: [{
    path: ':section',
    name: 'FaqIndex',
    // meta: {
    //   sectionTitle: 'navbar.customTitle',
    // },
    component: () => import(/* webpackChunkName: "faq-list" */ '../views/faq/FaqIndex.vue'),
    props: true,
  }],
}];
