import http from '@/services/http';

const glossaryTerms = {
  getGlossaryTerms(params) {
    return http.request({
      method: 'get',
      url: '/glossary',
      params,
    });
  },
};

export default glossaryTerms;
