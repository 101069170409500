import Vue from 'vue';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/it';
import { sync } from 'vuex-router-sync';
import VueApexCharts from 'vue-apexcharts';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

Vue.config.productionTip = false;

Vue.use(ElementUI, { locale });
Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);

sync(store, router); // done. Returns an unsync callback fn

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount('#app');
