export default {
  global: {
    welcome: 'Benvenuto',
    prev: 'Prec',
    next: 'Succ',
    back: 'Indietro',
    externalLinks: 'Link esterni',
    open: 'Apri',
    download: 'Scarica',
    insight: 'Approfondisci',
    noResults: 'Nessun risultato.',
    from: 'Dal',
    to: 'al',
  },
  user: {
    profile: 'Profilo Utente',
    editPassword: 'Modifica password',
    newPassword: 'Inserisci la nuova password',
    secondPassword: 'Inserisci nuovamente la nuova password',
    successTitle: 'Password modificata con successo!',
    successMessage: 'Riceverai a breve una conferma al tuo indirizzo email',
  },
  filters: {
    orderby: 'Ordina per:',
    search: 'Cerca e premi "Invio"',
    dateAsc: 'Data: dal meno recente',
    dateDesc: 'Data: dal più recente',
    titleAsc: 'Alfabetico: A - Z',
    titleDesc: 'Alfabetico: Z - A',
  },
  navbar: {
    dashboard: 'Dashboard',
    statistics: 'Statistiche',
    products: 'Prodotti',
    investmentAdvisory: 'Investment Advisory',
    communications: 'Comunicazioni e Circolari',
    usefulDocuments: 'Documenti Utili',
    faq: 'FAQ',
  },
  login: {
    username: 'Username',
    password: 'Password',
    emailAddress: 'Username o Indirizzo Email',
    login: 'Accedi',
    logout: 'Esci',
    passwordReset: 'Password dimenticata',
  },
  faq: {
    products: 'Prodotti',
    investmentAdvisory: 'Investment Advisory',
    other: 'Altro',
  },
  usefulDocuments: {
    policySection: 'Policy di Compagnia',
    instructionsSection: 'Istruzioni Operative',
    manualsSection: 'Manuali d\'uso',
    glossarySection: 'Glossario Assicurativo',
    manuals: 'Manuali',
  },
  products: {
    onSale: 'Prodotti in commercializzazione',
    notForSale: 'Prodotti non in commercializzazione',
    'on-sale': 'In commercio',
    'not-for-sale': 'Non in commercializzazione',
    documentation: 'Documentazione di prodotto',
    forms: 'Modulistica',
    archive: 'Storico',
    productFiles: 'File del prodotto',
  },
  investmentAdvisory: {
    'fund-selection': 'Fund Selection',
    sgr: 'SGR',
    'investment-solution': 'Soluzioni di investimento',
    'market-trends': 'Andamento dei mercati',
    files: 'File',
    documentation: 'Documentazione',
    archive: 'Storico',
    company: 'Company Profile',
    esg: 'ESG',
  },
  marketTrends: {
    type: 'Tipo:',
    archive: 'Archivio',
    daily: 'Giornaliero',
    weekly: 'Settimanale',
    outlook: 'Outlook',
    currentTopics: 'Temi di attualità',
  },
  dashboard: {
    user: 'Utente:',
    group: 'Rete:',
    dataDate: 'Dati aggiornati al',
    premiumsPaid: 'Premi versati',
    portfolio: 'Portafoglio',
  },
  statistics: {
    title: 'Performance di vendita e di portafoglio',
    filterLabel: 'Filtra i dati:',
    startDate: 'Data inizio',
    finishDate: 'Data fine',
    user: 'Utente:',
    group: 'Rete:',
    policiesTotal: 'Totale nuove polizze:',
    policiesVolume: 'Volume nuove polizze totali:',
    assetsUnderManagement: 'Assets under management:',
    newBusiness: 'New Business',
    portfolio: 'Portafoglio',
    subscriptions: 'valori in €, solo premi di sottoscrizione',
    boxDisclaimer: 'Dati al netto del portafoglio ex-Barclays',
    disclaimerFooterOne: 'I dati sono aggiornati al',
    disclaimerFooterTwo: 'e fanno riferimento alla nuova produzione (premi di sottoscrizione) e di portafoglio.',
    disclaimerFooterThree: 'I dati non comprendono il portafoglio ex-Barclays e le polizze di CNP Sviluppo.',
    fundsLabel: 'FONDI',
    gsLabel: 'GS',
    cteLabel: 'CTE',
  },
};
