<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss">
@import '@/assets/styles/index.scss';
</style>
