<template>
  <div class="external-links">
    <p class="external-links__title">{{ $t('global.externalLinks') }}</p>
    <a
      class="button external-links__button"
      v-for="(item, index) in buttons"
      :key="index"
      target="_blank"
      :href="item.external_link.url">
      {{ item.external_link.title }}
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ExternalLinks',
  computed: {
    ...mapGetters('options', ['currentOptions']),
    buttons() {
      return this.currentOptions.external_links;
    },
  },
};
</script>
