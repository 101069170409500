<template>
  <div class="navbar__wrapper">
    <NavBarMenu></NavBarMenu>
    <ExternalLinks></ExternalLinks>
    <UserGroups></UserGroups>
  </div>
</template>

<script>
import NavBarMenu from '@/components/NavBarMenu.vue';
import ExternalLinks from '@/components/ExternalLinks.vue';
import UserGroups from '@/components/UserGroups.vue';

export default {
  name: 'NavBar',
  components: {
    NavBarMenu,
    ExternalLinks,
    UserGroups,
  },
};
</script>
