// Communications routes
export default [
  {
    path: 'communications',
    name: 'Communications',
    meta: {
      title: 'navbar.communications',
    },
    redirect: 'communications/list',
    component: () => import(/* webpackChunkName: "communications" */ '../views/communications/Communications.vue'),
    props: true,
    children: [
      {
        path: 'list',
        name: 'CommunicationsList',
        component: () => import(/* webpackChunkName: "communications-list" */ '../views/communications/CommunicationsList.vue'),
        props: true,
      },
      {
        path: ':id',
        name: 'Communication',
        component: () => import(/* webpackChunkName: "single-communication" */ '../views/communications/Communication.vue'),
        props: true,
      },
    ],
  },
];
