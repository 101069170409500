import Vue from 'vue';
import Vuex from 'vuex';
import * as users from '@/store/modules/users';
import * as options from '@/store/modules/options';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    users,
    options,
  },
  getters: {
  },
});
