import http from '@/services/http';

const faqs = {
  getFaqs(params) {
    return http.request({
      method: 'get',
      url: '/faq',
      params,
    });
  },
};

export default faqs;
