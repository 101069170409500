import Vue from 'vue';
import VueRouter from 'vue-router';
import storage from 'store';
import Login from '@/views/Login.vue';
import PreLogin from '@/views/PreLogin.vue';
import PostLogin from '@/views/PostLogin.vue';
import store from '@/store';
import auth from '@/services/auth';
import { usersApi } from '@/api';
import dashboard from './dashboard';
import statistics from './statistics';
import users from './users';
import usefulDocuments from './usefulDocuments';
import communications from './communications';
import investmentAdvisory from './investmentAdvisory';
import faqs from './faqs';
import products from './products';

Vue.use(VueRouter);

const routes = [
  {
    path: '/admin',
    name: 'PostLogin',
    redirect: '/admin/dashboard',
    component: PostLogin,
    children: [
      ...dashboard,
      ...statistics,
      ...users,
      ...usefulDocuments,
      ...communications,
      ...investmentAdvisory,
      ...faqs,
      ...products,
    ],
  },
  {
    path: '/',
    name: 'PreLogin',
    redirect: '/login',
    component: PreLogin,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login,
        beforeEnter: (to, from, next) => {
          const authToken = storage.get('authToken');
          // console.log(store.getters['users/currentUser']);
          // if (store.getters['users/currentUser'] && authToken) {
          if (authToken) {
            next({ name: 'PostLogin' });
          } else {
            next();
          }
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (!!to.matched.find((route) => route.name === 'PostLogin') && !store.getters['users/currentUser']) {
    try {
      const response = await usersApi.getInfo();
      store.dispatch('users/setCurrentUser', response.data.current_user_data);
      if (!store.getters['users/currentUser']) {
        next({ name: 'Login' });
        return;
      }
    } catch (err) {
      if (err.response.status === 403 || !auth.authTokenExists()) {
        auth.logout();
        store.dispatch('users/setCurrentUser', null);
        next({ name: 'Login' });
        return;
      }
    }
  }
  next();
});

export default router;
