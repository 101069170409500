// Useful Documents routes
export default [{
  path: 'useful-documents',
  name: 'UsefulDocuments',
  meta: {
    title: 'navbar.usefulDocuments',
  },
  redirect: 'useful-documents/policy',
  component: () => import(/* webpackChunkName: "documents" */ '../views/usefulDocuments/UsefulDocuments.vue'),
  children: [
    {
      path: 'policy',
      name: 'Policy',
      component: () => import(/* webpackChunkName: "policy" */ '../views/usefulDocuments/Policy.vue'),
      props: true,
    },
    {
      path: 'instructions',
      name: 'Instructions',
      component: () => import(/* webpackChunkName: "instructions" */ '../views/usefulDocuments/Instructions.vue'),
      props: true,
    },
    {
      path: 'manuals',
      name: 'Manuals',
      component: () => import(/* webpackChunkName: "manuals" */ '../views/usefulDocuments/Manuals.vue'),
      props: true,
    },
    {
      path: 'glossary',
      name: 'Glossary',
      component: () => import(/* webpackChunkName: "glossary" */ '../views/usefulDocuments/Glossary.vue'),
      props: true,
    },
  ],
}];
