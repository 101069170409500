import http from '@/services/http';

const investmentAdvisory = {
  getInvestmentAdvisory(params) {
    return http.request({
      method: 'get',
      url: '/investment-advisory',
      params,
    });
  },
  getInvestmentAdvisorySingle(id) {
    return http.request({
      method: 'get',
      url: `/investment-advisory/${id}`,
    });
  },
  getInsights(params) {
    return http.request({
      method: 'get',
      url: '/insight',
      params,
    });
  },
  getInsight(id) {
    return http.request({
      method: 'get',
      url: `/insight/${id}`,
    });
  },
  getMacroAssets(params) {
    return http.request({
      method: 'get',
      url: '/macro-asset',
      params,
    });
  },
  getMacroAsset(id) {
    return http.request({
      method: 'get',
      url: `/macro-asset/${id}`,
    });
  },
  getMarketTrends(params) {
    return http.request({
      method: 'get',
      url: '/market-trend',
      params,
    });
  },
  getMarketTrend(id) {
    return http.request({
      method: 'get',
      url: `/market-trend/${id}`,
    });
  },
};

export default investmentAdvisory;
