// Dashboard routes
export default [{
  path: 'products',
  name: 'Products',
  meta: {
    title: 'navbar.products',
  },
  redirect: 'products/on-sale',
  component: () => import(/* webpackChunkName: "products" */ '../views/products/Products.vue'),
  props: true,
  children: [
    {
      path: ':section',
      name: 'ProductsList',
      component: () => import(/* webpackChunkName: "products-list" */ '../views/products/ProductsList.vue'),
      props: true,
    },
    {
      path: ':section/:id',
      name: 'Product',
      component: () => import(/* webpackChunkName: "single-product" */ '../views/products/Product.vue'),
      props: true,
      redirect: ':section/:id/documentation',
      children: [
        {
          path: ':subsection',
          name: 'ProductTab',
          component: () => import(/* webpackChunkName: "product-tab" */ '../views/products/ProductTab.vue'),
          props: true,
        },
      ],
    },
  ],
}];
