<template>
  <div class="login">
    <div class="login__circle"></div>
    <div class="login__rectangle"></div>
    <div class="login__wrapper">
      <img class="login__logo" alt="CNP Partners" src="@/assets/cnp-partners.png">

      <el-form class="login-form">
        <label for="username">{{ $t('login.username') }}:</label>
        <el-input
          name="username"
          id="username"
          class="login-form__input"
          :placeholder="$t('login.emailAddress')"
          v-model="loginForm.username"
          clearable>
        </el-input>
        <label for="password">{{ $t('login.password') }}:</label>
        <el-input
          name="password"
          id="password"
          class="login-form__input"
          :placeholder="$t('login.password')"
          v-model="loginForm.password"
          show-password>
        </el-input>
        <el-button
          type="primary"
          class="login-form__button"
          @click.prevent="loginUser"
          :disabled="!isFormValid"
          :loading="loading">
          {{ $t('login.login') }}
        </el-button>
      </el-form>
      <a
        class="login-form__password-reset"
        :href="passwordResetUrl"
        target="_blank">
        {{ $t('login.passwordReset') }}
      </a>
    </div>
  </div>
</template>

<script>
import { usersApi } from '@/api';
import { mapActions } from 'vuex';

export default {
  name: 'Login',
  data() {
    return {
      loginForm: {
        username: '',
        password: '',
      },
      loading: false,
      passwordResetUrl: `${process.env.VUE_APP_CNP_PARTNERS_URL}user-password-lost/`,
    };
  },
  computed: {
    isFormValid() {
      return this.loginForm.username !== '' && this.loginForm.password !== '';
    },
  },
  methods: {
    async loginUser() {
      try {
        this.loading = true;
        await usersApi.getToken(this.loginForm);
        this.loading = false;
        this.$router.push({ name: 'PostLogin' });
      } catch (error) {
        this.loading = false;
        if (error && error.response) {
          this.$notify({
            type: 'error',
            dangerouslyUseHTMLString: true,
            message: error.response.data.message,
          });
        }
        throw error;
      }
    },
    async validateToken() {
      try {
        await usersApi.validateToken();
      } catch (error) {
        this.loading = false;
        if (error && error.response) {
          this.$notify({
            type: 'error',
            dangerouslyUseHTMLString: true,
            message: error.response.data.message,
          });
        }
        throw error;
      }
    },
    ...mapActions('users', ['setCurrentUser']),
  },
};
</script>
