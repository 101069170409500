export const namespaced = true;

export const mutations = {
  SET_RESERVED_AREA_OPTIONS(state, options) {
    state.options = options;
  },
};

export const actions = {
  setOptions({ commit }, optionsData) {
    commit('SET_RESERVED_AREA_OPTIONS', optionsData);
    return optionsData;
  },
};

export const getters = {
  currentOptions: (state) => state.options,
};

export const state = {
  options: {},
};
