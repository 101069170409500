import axios from 'axios';
import storage from 'store';
import auth from '@/services/auth';
import store from '@/store';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use((config) => {
  const token = storage.get('authToken');

  if (token) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  }
  return config;
}, (error) => {
  throw error;
});

axios.interceptors.response.use((response) => {
  const { token } = response.data || {};
  if (token) {
    storage.set('authToken', token);
  }
  return response;
}, (error) => {
  if ((error.response
    && (error.response.status === 403 || error.response.status === 401))
    || !auth.authTokenExists()) {
    auth.logout();
    store.dispatch('users/setCurrentUser', null);
    return;
  }
  throw error;
});

export default axios;
