// Investment Advisory routes
export default [
  {
    path: 'investment-advisory',
    name: 'InvestmentAdvisory',
    meta: {
      title: 'navbar.investmentAdvisory',
    },
    redirect: 'investment-advisory/fund-selection',
    component: () => import(/* webpackChunkName: "investment" */ '../views/investmentAdvisory/InvestmentAdvisory.vue'),
    props: true,
    children: [
      {
        path: ':section',
        name: 'InvestmentAdvisoryList',
        component: () => import(/* webpackChunkName: "investment-list" */ '../views/investmentAdvisory/InvestmentAdvisoryList.vue'),
        props: true,
      },
      {
        path: ':section/:id',
        name: 'Investment',
        component: () => import(/* webpackChunkName: "single-investment" */ '../views/investmentAdvisory/Investment.vue'),
        props: true,
        redirect: ':section/:id/documentation',
        children: [
          {
            path: ':subsection',
            name: 'InvestmentAdvisoryTab',
            component: () => import(/* webpackChunkName: "investment-tab" */ '../views/investmentAdvisory/InvestmentAdvisoryTab.vue'),
            props: true,
          },
        ],
      },
    ],
  },
  {
    path: 'insights',
    name: 'Insights',
    component: () => import(/* webpackChunkName: "insights" */ '../views/investmentAdvisory/Insights.vue'),
    props: true,
    meta: {
      title: 'navbar.investmentAdvisory',
    },
    children: [
      {
        path: ':term',
        name: 'InsightsList',
        component: () => import(/* webpackChunkName: "insights-list" */ '../views/investmentAdvisory/InsightsList.vue'),
        props: true,
      },
      {
        path: ':term/:id',
        name: 'Insight',
        component: () => import(/* webpackChunkName: "insight" */ '../views/investmentAdvisory/Insight.vue'),
        props: true,
      },
    ],
  },
  {
    path: 'market-trend',
    name: 'MarketTrends',
    component: () => import(/* webpackChunkName: "market-trends" */ '../views/investmentAdvisory/MarketTrends.vue'),
    props: true,
    meta: {
      title: 'navbar.investmentAdvisory',
    },
    children: [
      {
        path: ':type',
        name: 'MarketTrendsList',
        component: () => import(/* webpackChunkName: "market-trends-list" */ '../views/investmentAdvisory/MarketTrendsList.vue'),
        props: true,
      },
      {
        path: ':type/:id',
        name: 'MarketTrend',
        component: () => import(/* webpackChunkName: "market-trend" */ '../views/investmentAdvisory/MarketTrend.vue'),
        props: true,
      },
    ],
  },
];
