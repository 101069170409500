<template>
  <el-row class="header" type="flex" align="middle" :gutter="20">
    <el-col class="header__logo">
      <img class="header__logo-image" alt="CNP Partners" src="@/assets/logo@2x.png">
    </el-col>
    <el-col class="header__menu" :span="2">
      <a class="header__menu-toggle"
        @click.prevent="toggleMenu">
        <span class="icon-menu"></span>
      </a>
    </el-col>
    <el-col class="header__title" :span="12">
      {{ title }}
    </el-col>
    <el-col class="header__user">
      <router-link class="header__user-profile"
        :to="{ name: 'User', params: { username: currentUser.user_nicename } }">
        {{ $t('global.welcome') }} {{ currentUser.user_display_name }}
      </router-link>
      <a href="#"
        class="header__user-logout"
        @click.prevent="logoutUser">
        ESCI
      </a>
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '@/store';
import auth from '@/services/auth';

export default {
  name: 'Header',
  computed: {
    ...mapGetters('users', ['currentUser']),
    title() {
      if (this.$route.meta.sectionTitle) return this.$t(this.$route.meta.sectionTitle);

      const parentRoute = this.$route.matched.find((route) => route.meta.title);
      return parentRoute ? this.$t(parentRoute.meta.title) : '';
    },
  },
  methods: {
    logoutUser() {
      auth.logout();
      store.dispatch('users/setCurrentUser', null);
    },
    toggleMenu() {
      this.$emit('toggle-menu');
    },
  },
};
</script>
