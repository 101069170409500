import http from '@/services/http';

const users = {
  getToken(attributes) {
    return http.request({
      method: 'post',
      baseURL: process.env.VUE_APP_API_AUTH_URL,
      url: '/token',
      data: attributes,
    });
  },
  validateToken() {
    return http.request({
      method: 'post',
      baseURL: process.env.VUE_APP_API_AUTH_URL,
      url: '/token/validate',
    });
  },
  getInfo() {
    return http.request({
      method: 'get',
      url: '/users/me',
    });
  },
  setNewPassword(userId, params) {
    return http.request({
      method: 'post',
      url: `/users/${userId}`,
      data: params,
    });
  },
  getStatistics(params) {
    return http.request({
      method: 'post',
      baseURL: process.env.VUE_APP_API_RESERVED_AREA_URL,
      url: '/statistics',
      data: params,
    });
  },
};

export default users;
