<template>
  <el-menu class="side-menu">
    <el-menu-item class="side-menu__item"
      v-for="(item, index) in items"
      :key="index">
      <router-link
        :to="{ name: item.name }"
        class="side-menu__link"
        :class="isInvestmentActive(item.name)">
        <div class="side-menu__icon-wrapper">
          <span class="side-menu__icon" :class="`icon-${item.icon}`"></span>
        </div> {{ $t(`navbar.${item.label}`) }}
      </router-link>
    </el-menu-item>
  </el-menu>
</template>

<script>
import menuItems from '@/config/navbar';

export default {
  name: 'NavBarMenu',
  data() {
    return {
      items: menuItems,
      investmentChildren: [
        'InsightsList',
        'Insight',
        'MarketTrendsList',
        'MarketTrend',
      ],
    };
  },
  methods: {
    isInvestmentActive(name) {
      if (
        name === 'InvestmentAdvisory'
        && (this.investmentChildren.includes(this.$route.name))
      ) {
        return 'router-link-active';
      }
      return '';
    },
  },
};
</script>
